// 质押贷款
<template>
  <div class="financial-pledgeLoan" v-if="data.length">
    <Info class="pledgeLoan-info" />
    <section id="certify">
      <swiper v-if="data.length" :options="swiperOption" ref="mySwiper">
        <swiper-slide class="back-border" v-for="item in data" :key="item.id">
          <img :src="$base+item.logo" />
          <div class="pledgeLoan-details">
            <h3>{{item.name}}</h3>
            <p v-html="item.product_explain"> </p>
          </div>
        </swiper-slide>
      </swiper>
      <div class="swiper-controller">
        <div class="swiper-pagination"></div>
        <div class="swiper-button-prev swiper-button-white"></div>
        <div class="swiper-button-next swiper-button-white"></div>
      </div>
    </section>
  </div>
</template>

<script>
import Info from '@/components/Info'
import { getProduct } from '@/api'
export default {
  components: {
    Info
  },
  data () {
    return {
      data: [],
      swiperOption: {
        watchSlidesProgress: true,
        slidesPerView: 'auto',
        centeredSlides: true,
        loop: true,
        autoplay: false,
        loopedSlides: 5,
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        on: {
          progress: function (progress) {
            let slideWidth = ($('.swiper-slide').width() * 0.8)
            for (let i = 0; i < this.slides.length; i++) {
              let slide = this.slides.eq(i)
              let slideProgress = this.slides[i].progress

              let translate = 0
              if ((slideProgress * slideWidth) > 799) {
                translate = (slideProgress * slideWidth) + 12 + 'px'
              } else if ((slideProgress * slideWidth) < -799) {
                translate = (slideProgress * slideWidth) - 12 + 'px'
              } else {
                translate = (slideProgress * slideWidth) + 'px'
              }
              let translateY = Math.abs(slideProgress * 25) + 'px'
              let scale = 1 - Math.abs(slideProgress) / 10
              let zIndex = 999 - Math.abs(Math.round(10 * slideProgress))
              slide.transform(
                'translate(' +
                translate +
                ',' +
                translateY +
                ') scale(' +
                scale +
                ')'
              )
              slide.css('zIndex', zIndex)
              slide.css('opacity', 1)
              if (Math.abs(slideProgress) > 3) {
                slide.css('opacity', 0)
              }
            }
          },
          setTransition: function (transition) {
            for (let i = 0; i < this.slides.length; i++) {
              let slide = this.slides.eq(i)
              slide.transition(transition)
            }
          },
        },
      }
    };
  },
  mounted () {
    this.getData()
  },
  methods: {
    async getData () {
      let { data } = await getProduct()
      this.data = data
    },
  },
};
</script>

<style scoped lang="less">
section {
  margin-bottom: 20px;
}
.pledgeLoan-info {
  line-height: 30px;
  margin-bottom: 60px;
}

/* CSS Document */
#certify {
  width: 856px;
  margin: 0 auto;
  .swiper-container {
    padding-bottom: 60px;
  }
  .swiper-slide {
    width: 550px;
    min-height: 502px;
    background: #fff;
    overflow: hidden;
    box-shadow: 0px 0px 10px 0px rgba(158, 158, 158, 0.31);
    img {
      display: block;
      width: 100%;
      height: 310px;
    }
    .pledgeLoan-details {
      padding: 20px 40px;
      h3 {
        font-size: 24px;
        margin-bottom: 28px;
        text-align: center;
      }
      p {
        text-indent: 20px;
        line-height: 20px;
      }
    }
  }
  /deep/.swiper-controller {
    position: relative;
    .swiper-pagination {
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      .swiper-pagination-bullet {
        width: 12px;
        height: 12px;
        border-radius: 7px;
        border: 3px solid #f1b54c;
        margin: 0 8px;
        background-color: transparent;
        opacity: 1;
      }
      .swiper-pagination-bullet-active {
        background-color: #f1b54c;
      }
    }
    .swiper-button-white {
      padding: 10px;
      background-color: #f1b54c;
      width: 16px;
      height: 16px;
      background-size: 100% 60%;
      border-radius: 7px;
      opacity: 0.6;
      margin-top: -19px;
      &:hover {
        opacity: 1;
      }
      &.swiper-button-prev {
        left: 34%;
      }
      &.swiper-button-next {
        right: 34%;
      }
    }
  }
}
</style>
